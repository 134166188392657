<template>
  <div class="copyright-and-payments">
    <Socials v-show="isMobileOrTablet" />
    <span class="copyright"
      >© 2022 Sogni di Cristallo<br />
      Via Castellantico 17 30035 Mirano Venezia / Partita IVA: 04513400277 /
      T+39 041 4762538
    </span>
    <nuxt-img
      format="webp"
      :src="
        isMobileOrTablet
          ? '/logo/mobilePayments.svg'
          : '/logo/desktopPayments.svg'
      "
      height="16"
      alt="payment"
      class="payment"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import Socials from './Socials.vue';

export default defineComponent({
  name: 'FooterCopyright',
  setup() {
    const { isMobileOrTablet } = useWindow();
    return {
      isMobileOrTablet,
    };
  },
  components: { Socials },
});
</script>

<style lang="scss">
.copyright-and-payments {
  @include for-mobile {
    text-align: center;
    padding: 2.5rem 1.25rem;
    .socials {
      padding-bottom: 1.875rem;
    }
  }
  @include for-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4375rem 1.25rem 1.875rem;
    grid-column-start: 1;
    grid-column-end: span 3;
  }
  .copyright {
    @include label-s;
    text-transform: uppercase;
  }
  .payment {
    @include for-mobile {
      padding-top: 1.875rem;
    }
    max-width: 90vw;
    object-fit: contain;
  }
}
</style>
