<template>
  <div class="certification" v-if="data">
    <p class="certification__text">
      {{ data.text }}
    </p>
    <nuxt-img
      v-if="data.image"
      :src="data.image.desktop"
      alt="Made in Italy"
      width="85"
      height="85"
      class="certification__image"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Certification',
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.certification {
  margin: 2.5rem 1.25rem 0;
  @include for-mobile {
    text-align: center;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid lightgray;
  }

  @include for-desktop {
    margin-left: 1.25rem;
    margin-top: 3.5rem;
    margin-right: 3.6875rem;
    margin-bottom: 3.6875rem;
  }
  &__text {
    @include mobile-h6;
    @include for-desktop {
      @include desktop-h6;
    }
  }
  &__image {
    padding-top: 1.25rem;
    @include for-desktop {
      padding-top: 1.875rem;
    }
  }
}
</style>
