<template>
  <div class="newsletter-subscription">
    <div class="newsletter-subscription__title">
      <span v-if="data">
        {{ data.title }}
      </span>
      <component
        v-if="!isDesktop"
        :is="showMobileContent ? 'IconMinusBlack' : 'IconPlusBlack'"
        @click="showMobileContent = !showMobileContent"
      />
    </div>
    <div v-show="showMobileContent || isDesktop">
      <p v-if="data" class="newsletter-subscription__text">
        {{ data.subtitle }}
      </p>
      <NewsletterForm form-key="newsletter-form" />
      <Socials v-show="isDesktop" />
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from '@nuxtjs/composition-api';
import { IconPlusBlack, IconMinusBlack } from '~/components/General/Icons';
import { useWindow } from '~/composables';
import { CustomInput, CustomCheckbox } from '~/components/General';
import { NewsletterForm } from '~/components/Newsletter';
import Socials from './Socials.vue';

export default defineComponent({
  name: 'NewsletterSubscription',
  components: {
    CustomInput,
    CustomCheckbox,
    Socials,
    NewsletterForm,
    IconPlusBlack,
    IconMinusBlack,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { isDesktop } = useWindow();
    const showMobileContent = ref(false);

    return {
      showMobileContent,
      isDesktop,
    };
  },
});
</script>

<style lang="scss">
.newsletter-subscription {
  @include for-mobile {
    padding-top: var(--spacer-base);
    padding-left: var(--spacer-base);
    padding-right: var(--spacer-base);
    padding-bottom: 1.875rem;
  }
  &__title {
    text-transform: none;
    @include mobile-h6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include for-desktop {
      padding: 3.3125rem 0 0 0;
      margin: 0 auto;
      @include desktop-h6;
    }
  }
  &__text {
    @include paragraph-s;
    padding-top: 1.3125rem;
  }
  .socials {
    padding-top: 2.6875rem;
  }
}
</style>
